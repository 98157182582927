import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { environment as Config } from 'src/environments/environment';
import { LoginService } from 'src/app/services/login.service';
import { PoliticasService } from 'src/app/services/politicas.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalDeletarContaComponent } from '../modal-deletar-conta/modal-deletar-conta.component';

@Component({
  selector: 'app-modal-atualizacao-privacidade',
  templateUrl: './modal-atualizacao-privacidade.component.html',
  styleUrls: ['./modal-atualizacao-privacidade.component.scss'],
})
export class ModalAtualizacaoPrivacidadeComponent implements OnInit {
  politica: string = "";
  data = {
    title: null,
    subtitle: null,
    declaracao: null,
    textButton: null,
    buttonShow: null,
    type: null,
    close: null
  }

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private userService: UserService,
    private utilService: UtilService,
    private loginService: LoginService,
    private politicaService: PoliticasService,
    private _modal: ModalController
  ) {
  }

  ngOnInit() {
    this.politicaService.getPolitica().then(d => {
      let pol: any = d;
      this.politica = pol.text;
    }).catch(err => {
      this.utilService.presentToast({ msg: Config.MENSAGEM.errorService });
    });

    const data = this.navParams.get('data');
    if (data) {
      this.data = data;
    }
  }

  concordo() {
    this.utilService.analyticsClickEvent('pop-up', 'click', 'popup declaração de privacidade');
    this.updateTermPopup();
  }

  updateTermPopup() {
    let token = localStorage.getItem("token");
    this.userService.getMyUser(token).then((usuario: any) => {
      let user = new FormData();
      user.append("id", usuario.id);
      user.append("name", usuario.name);
      user.append("lastName", usuario.lastName);
      user.append("phone", usuario.phone);
      user.append("checktermupdate", new Date().toISOString());
      this.userService.updateTermPopup(token, user).then(d => {
        this.modalCtrl.dismiss();
      }).catch(err => {
        if (err.status == 401) {
          this.loginService.refreshToken().then(d => {
            var objUser: any = d;
            localStorage.setItem("token", objUser.accessToken);
            localStorage.setItem("refreshToken", objUser.refreshToken);
            this.userService.updateTermPopup(token, user).then(d => { }).catch(err => { });
          }).catch(err => { });
        }
      });
    }).catch(err => {
      if (err.error.cod == "400#0009") {
        this.utilService.showModalBlackList();
        this.updateTermPopup();
      }
    });
  }

  async openDelete() {
    var modalDelete: any = {
      type: Config.TYPE.info,
      close: true
    }
    const modal = this._modal.create({
      component: ModalDeletarContaComponent,
      componentProps: {
        data: Object.assign({}, modalDelete)
      },
      backdropDismiss: false
    });

    modal.then((modal) => modal.present());

    await modal.then((modal) => {
      modal.onDidDismiss().then((d: any) => {
        if (d.data.deletado) {
          this.modalCtrl.dismiss().then(() => {
            this.modalCtrl.dismiss();
          });
        }
      });
    });
  }
}
