import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'vantagens', loadChildren: () => import('./vantagens/vantagens.module').then( m => m.VantagensPageModule)},
  //{ path: 'splashscreen', loadChildren: () => import('./splashscreen/splashscreen.module').then( m => m.SplashscreenPageModule)},
  { path: 'login', loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)},
  { path: 'parcerias', loadChildren: () => import('./parcerias/parcerias.module').then( m => m.ParceriasPageModule)},
  { path: 'recarga', loadChildren: () => import('./recarga/recarga.module').then( m => m.RecargaPageModule)},
  { path: 'extrato', loadChildren: () => import('./extrato/extrato.module').then( m => m.ExtratoPageModule)},
  { path: 'meus-dados', loadChildren: () => import('./meus-dados/meus-dados.module').then( m => m.MeusDadosPageModule)},
  { path: 'mais', loadChildren: () => import('./mais/mais.module').then( m => m.MaisPageModule)},
  { path: 'pesquisa', loadChildren: () => import('./pesquisa/pesquisa.module').then( m => m.PesquisaPageModule)},
  { path: 'pesquisa-concluida', loadChildren: () => import('./pesquisa-concluida/pesquisa-concluida.module').then( m => m.PesquisaConcluidaPageModule)},
  //{ path: 'login-email', loadChildren: () => import('./login-email/login-email.module').then( m => m.LoginEmailPageModule)},
  { path: 'registro', loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)},
  { path: 'alterar-senha', loadChildren: () => import('./alterar-senha/alterar-senha.module').then( m => m.AlterarSenhaPageModule)},
  { path: 'ajuda', loadChildren: () => import('./ajuda/ajuda.module').then( m => m.AjudaPageModule)},
  { path: 'ajuda-lista', loadChildren: () => import('./ajuda-lista/ajuda-lista.module').then( m => m.AjudaListaPageModule)},
  //{ path: 'adicionar-cartao', loadChildren: () => import('./adicionar-cartao/adicionar-cartao.module').then( m => m.AdicionarCartaoPageModule)},
  { path: 'adicionar-giro', loadChildren: () => import('./adicionar-giro/adicionar-giro.module').then( m => m.AdicionarGiroPageModule)},
  { path: 'fale-conosco', loadChildren: () => import('./fale-conosco/fale-conosco.module').then( m => m.FaleConoscoPageModule)},
  { path: 'fale-conosco-confirmacao', loadChildren: () => import('./fale-conosco-confirmacao/fale-conosco-confirmacao.module').then( m => m.FaleConoscoConfirmacaoPageModule)},
  { path: 'termos', loadChildren: () => import('./termos/termos.module').then( m => m.TermosPageModule)},
  { path: 'politicas', loadChildren: () => import('./politicas/politicas.module').then( m => m.PoliticasPageModule)},
  { path: 'parceria-detalhe', loadChildren: () => import('./parceria-detalhe/parceria-detalhe.module').then( m => m.ParceriaDetalhePageModule)},
  { path: 'chat', loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)},
  { path: 'deletar-conta', loadChildren: () => import('./deletar-conta/deletar-conta.module').then( m => m.DeletarContaPageModule)},
  { path: 'confirma-recarga', loadChildren: () => import('./confirma-recarga/confirma-recarga.module').then( m => m.ConfirmaRecargaPageModule)},
  { path: 'onde-esta-trem', loadChildren: () => import('./onde-esta-trem/onde-esta-trem.module').then( m => m.OndeEstaTremPageModule)},


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
