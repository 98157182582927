import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OndeEstaTremService } from 'src/app/services/ondeEstaTrem.service';

interface LineModel {
  linha: number;
  status: string;
  corFonteStatus: string;
  corStatus: string;
}

@Component({
  selector: 'app-situacao-linhas',
  templateUrl: './situacao-linhas.component.html',
  styleUrls: ['./situacao-linhas.component.scss'],
})
export class SituacaoLinhasComponent implements OnInit {
  @Input("update-frequence") frequence : number = null;
  @Input("loop") loop : Boolean = false;
  @Input("direction") direction : string = "column";
  @Input("readable") readable : string = "false";
  @Input("link") link : Boolean = false;
  public lines: LineModel[] = [];
  public options = {
    pager: false,
    autoplay: true,
    loop: false,
    speed: 500,
    slidesPerView: 'auto',
    spaceBetween: 10,
    infinite: false
  }
  constructor(
    private router: Router,
    private chRef: ChangeDetectorRef,
    private service: OndeEstaTremService
  ) { }
  ngOnInit() {
    let frasesStatus : { linha: number, texto: string, status: string }[] = [];
    this.getStatus(0, frasesStatus);
  }

  getStatus(i : number = 0, frasesStatus : { linha: number, texto: string, status: string }[] = []) {
    this.service.getStatusLinhas().then((data: LineModel[]) => {
      this.lines = JSON.parse(JSON.stringify(data.sort((a,b) => a.linha - b.linha)));
      let frasesLer = [];
      if(i == 0) {
        frasesStatus = this.lines.map(x => ({ linha: x.linha, texto: `A linha ${x.linha} está ${x.status}`, status: x.status }));
        let _statusAnormais = frasesStatus.filter(x => x.status != 'Normal');
        if(_statusAnormais.length) {
          _statusAnormais.forEach(x => frasesLer.push(x.texto));
        } else {
          frasesLer = ['Todas as linhas estão operando normalmente'];
        }
        if(frasesLer.length && this.readable == "true") {
          console.log('LER'); frasesLer.forEach(x => console.log('-', x));
        }
      } else {
        this.lines.forEach(l => {
          let index = frasesStatus.findIndex(f => f.linha == l.linha);
          if(index != -1) {
            if(l.status != frasesStatus[index].status) {
              frasesStatus[index].status = l.status;
              frasesStatus[index].texto = `A linha ${l.linha} está ${l.status}`;
              frasesLer.push(frasesStatus[index].texto);
            }
          }
        });
        this.chRef.detectChanges();
        if(frasesLer.length && this.readable == "true") {
          console.log('LER'); frasesLer.forEach(x => console.log('-', x));
        }
      }
      setTimeout(() => {
        i++;
        this.getStatus(i, frasesStatus);
      }, 30 * 1000);
    });
  }

  open() {
    if(this.link) {
      this.router.navigate(['onde-esta-trem']);
    }
  }
}
