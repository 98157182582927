import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as Config } from 'src/environments/environment';
import { UtilService } from './util.service';
declare var localStorage: Storage;

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private _eventList = [];

  public get_eventList() {
    return this._eventList;
  }

  public set_eventList(_eventList) {
    this._eventList = _eventList;
  }

  constructor(public http: HttpClient, private utilService: UtilService) { }

  public geEventNames() {
    return new Promise((resolve, reject) => {
      this.http.get(`${Config.SYSTEM.urlApi}EventTrack/eventnames`, { headers: this.utilService.headerPadrao() })
        .subscribe((data: any) => {
          this._eventList = data;
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public addEventOk(eventName: string) {
    this.create(this.createEventObj(eventName, "", -1));
  }

  public addEvent(eventName: string, failType: string, targetUser: number = -1) {
    this.create(this.createEventObj(eventName, failType, targetUser));
  }

  private create(event) {
    this.http.post(`${Config.SYSTEM.urlApi}EventTrack`, JSON.parse(event), { headers: this.utilService.headerPadrao() })
      .subscribe(d => { }, err => { });
  }

  private getEventByName(name: string) {
    var retorno = -1;
    for (let index = 0; index < this._eventList.length; index++) {
      const element = this._eventList[index];
      if (retorno == -1)
        retorno = (element.name).toUpperCase().Trim() === name.toUpperCase().trim() ? index : -1;
    }
    return this._eventList[retorno];
  }

  private async createEventObj(eventName: string, failType: string, targetUser: number) {
    var eventObj = await this.getEventByName(eventName);
    if (eventName !== null) {
      if (failType == null)
        failType = '';
      if (targetUser == null)
        targetUser = 0;

      var retorno = '{';
      retorno = retorno + '"eventName": "' + eventName + '",';
      retorno = retorno + '"idUser": 0,';
      retorno = retorno + '"failType": "' + failType + '",';
      retorno = retorno + '"description": "' + eventObj.description + '",';
      retorno = retorno + '"grupo": "",';
      retorno = retorno + '"dataHora": "",';
      retorno = retorno + '"ipAdress": 0,';
      retorno = retorno + '"created": "",';
      retorno = retorno + '"modified": "",';
      retorno = retorno + '"targetUser": "' + targetUser + '",';
      retorno = retorno + '"id": 0' + ',';
      retorno = retorno + '"EventId": ' + eventObj.eventid;
      retorno = retorno + '}';
      return retorno
    }
  }

}
