import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as Config } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PoliticasService {

  constructor(public http: HttpClient) { }

  getPolitica() {
    return new Promise((res, rej) => {
      this.http.get(`${Config.SYSTEM.urlApi}privacypolicy/getactive`).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }
}
