import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import _ from 'lodash';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-modal-extrato-filtro',
  templateUrl: './modal-extrato-filtro.component.html',
  styleUrls: ['./modal-extrato-filtro.component.scss'],
})
export class ModalExtratoFiltroComponent implements OnInit {
  _: any = {
    objectkeys: Object.keys,
    parseInt: parseInt
  }

  filtroTemp: any = {
    dataDe: { dia: "1", mes: "1", ano: new Date().getFullYear() },
    dataAte: { dia: "1", mes: "1", ano: new Date().getFullYear() },
    valor: { de: "0", a: "50000" }
  }

  years = _.range(new Date().getFullYear() - 5, new Date().getFullYear() + 1);
  meses_array: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  dias_no_mes: any = { dataDe: 10, dataAte: 10 }

  constructor(
    public popoverCtrl: PopoverController,
    public navParams: NavParams,
    private utilService: UtilService
  ) { }

  doSearch() {
    this.utilService.analyticsClickEvent('extrato', 'click', 'filtrar');
    this.popoverCtrl.dismiss({
      temp: { ...this.filtroTemp },
      atualizar: true
    });
  }

  changeMonth(tipo) {
    let month = this.filtroTemp[tipo].mes;
    let num_days = new Date(this.filtroTemp[tipo].ano, month, 0).getDate();
    this.dias_no_mes[tipo] = Array.from(Array(num_days).keys());
  }

  convert(de, valor) {
    if (typeof valor == 'object') {
      let r = '';
      for (let i in valor) {
        r = r + valor[i];
      }
      valor = r;
    }
    valor = valor.toString();
    valor = valor.replace(/[^0-9]+/gm, '');
    valor = ("000" + valor).toString();
    valor = valor.substr(0, valor.length - 2) + '.' + valor.substr(-2);
    valor = parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    this.filtroTemp.valor[de] = valor;
  }

  ngOnInit() {
    var parseValues = this.navParams.get('temp');

    this.filtroTemp.dataDe = { ...parseValues.dataDe };
    this.filtroTemp.dataAte = { ...parseValues.dataAte };

    this.convert('de', { ...parseValues.valor.de });
    this.convert('a', { ...parseValues.valor.a });
    this.changeMonth('dataDe');
    this.changeMonth('dataAte');
  }

}
