import { FormControl, AbstractControl } from '@angular/forms';
export class UtilsValidator {
    static cpf(control: FormControl): any {
        let value = ""+control.value;
        value = (value||"").replace(/\./g,'').replace(/\-/g,'');
        if(value.length != 11) return {'invalidCPF': true};
        var soma = 0;
        var resto;
        var inputCPF = value;
        if(inputCPF == '00000000000') return {'invalidCPF': true};
        for(let i=1; i<=9; i++) soma = soma + parseInt(inputCPF.substring(i-1, i)) * (11 - i);
        resto = (soma * 10) % 11;
        if((resto == 10) || (resto == 11)) resto = 0;
        if(resto != parseInt(inputCPF.substring(9, 10))) return {'invalidCPF': true};
        soma = 0;
        for(let i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i-1, i))*(12-i);
        resto = (soma * 10) % 11;
        if((resto == 10) || (resto == 11)) resto = 0;
        if(resto != parseInt(inputCPF.substring(10, 11))) return {'invalidCPF': true};
        return {};
    }

    static email(control: FormControl): any{
        let value = (control.value || "");
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var email = re.test(String(value).toLowerCase());
        return email ? {} : {invalidEmail: true};
    }

    static telefone(control: FormControl): any {
        let value = (control.value || "");
        return value.length == 14 || value.length == 15 ? {} : { 'invalidTel': true };
    }

    static fotoObrigatoria(control: FormControl): any {
        let value = (control.value || "");
        return value ? {} : {requiredPhoto: true};
    }

    static passwordMatch(control: AbstractControl) {
        let password = control.get('senha').value;
        let confirmPassword = control.get('confirmacaoSenha').value;
        if (password != confirmPassword) {
            control.get('confirmacaoSenha').setErrors({ 'invalidMatchPassword': true });
        }
    }

    static emailMatch(control: AbstractControl) {
        let email = control.get('email').value;
        let confirmEmail = control.get('confirmacaoEmail').value;
        if (email !== confirmEmail) {
            control.get('confirmacaoEmail').setErrors({ 'invalidMatchEmail': true });
        }
    }

    static giroMatch(control: AbstractControl) {
        let number = control.get('numeroGiro').value;
        let confirmNumber = control.get('confirmacaoNumGiro').value;
        if (number !== confirmNumber) {
            control.get('confirmacaoNumGiro').setErrors({ 'invalidMatchGiro': true });
        }
    }

    static checkPassword(control: FormControl): any {
        let value = (control.value || "");
        if(value.length >= 8 && value.match(/[a-z]+/) && value.match(/[A-Z]+/) && value.match(/[0-9]+/)){
            return {};
        }else{
            return {invalidKey: true};
        }
    }

    static checkBirthday(control: AbstractControl): any {
        let value = control.get('dataNascimento').value;
        var re = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
        var date = true;
        if(value != ""){
            date = re.test(String(value));
        }
        return date ? {} : control.get('dataNascimento').setErrors({ 'invalidBirthday': true });
    }

    static checkMenorDezesseis(control: AbstractControl): any { 
      let value = control.get('dataNascimento').value;
      var re = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
      var date = true;
      if(value != ""){
          date = re.test(String(value));
      }
      
      if(date) {
        let aux = value.split("/");
        let birthday = new Date(aux[1]+"/"+aux[0]+"/"+aux[2]);
        let today = new Date();
        var idade = 0;
        if(birthday.getMonth() < today.getMonth() || (birthday.getMonth() == today.getMonth() && birthday.getDate() <= today.getDate())) {
          idade = today.getFullYear() - birthday.getFullYear();
        }else {
          idade = today.getFullYear() - birthday.getFullYear();
          idade--;
        }
      }
      return idade >= 16 ? {} : control.get('dataNascimento').setErrors({ 'invalidSixteen': true });
    }
}