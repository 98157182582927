import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { environment as Config } from 'src/environments/environment';
@Component({
  selector: 'app-popup-banner',
  templateUrl: './popup-banner.component.html',
  styleUrls: ['./popup-banner.component.scss'],
})
export class PopupBannerComponent implements OnInit {
  tipo: any = "video";
  conteudo: any = "";
  link: any = "";
  maxHeight: any = "auto";
  maxWidth: any = "auto";
  @Input() data: any;

  constructor(
    public renderer: Renderer2,
    public modalCtrl: ModalController,
    private domSanitizer: DomSanitizer,
    platform: Platform
  ) {
    platform.ready().then(() => {
      this.maxHeight = (platform.height() - 150) + 'px';
      this.maxWidth = (platform.width()) + 'px';
    });
  }

  ngOnInit() {

    this.renderer.addClass((this as any).modal, 'popup-banner');
    this.tipo = 'imagem';
    let conteudo = `${Config.SYSTEM.path}/${this.data.image.imageUrl}`;
    this.link = this.data.link;

    if (this.tipo == 'video' || this.tipo == 'imagem') {
      this.conteudo = this.domSanitizer.bypassSecurityTrustResourceUrl(conteudo);
    } else {
      this.conteudo = conteudo;
    }
  }

  toLink() {
    if (this.link && this.link != "#") {
      if (this.link == "x") {
        this.close();
      } else {
        window.open(this.link, "_system");
        this.modalCtrl.dismiss();
      }
    }
  }
  close() {
    this.modalCtrl.dismiss();
  }

}
