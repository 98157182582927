import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { environment as Config } from 'src/environments/environment';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  modal: any = {
    title: "",
    text: "",
    textButton: "confirmar",
    buttonShow: false,
    close: true
  }
  formatCurrency: any;
  constructor(public popoverCtrl: PopoverController, public navParams: NavParams) { }

  ngOnInit() {
    var parseValues = this.navParams.get('temp');
    this.formatCurrency = function (valor) {
      valor = parseFloat(valor);
      return isNaN(valor) ? '--' : valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    if (parseValues.type == Config.TYPE.confirmPay) {
      this.modal = {
        title: parseValues.title,
        value: parseValues.value,
        number: parseValues.number,
        card: parseValues.card,
        textButton: parseValues.textButton,
        buttonShow: parseValues.buttonShow,
        type: parseValues.type,
        msgPromotion: parseValues.msgPromotion,
        valuePromotion: parseValues.valuePromotion,
        close: parseValues.close == undefined ? true : parseValues.close
      };
    } else {
      this.modal = {
        title: parseValues.title,
        text: parseValues.text,
        textButton: parseValues.textButton,
        buttonShow: parseValues.buttonShow,
        type: parseValues.type,
        close: parseValues.close == undefined ? true : parseValues.close
      };
    }
  }

  closeModal() {
    this.popoverCtrl.dismiss({
      confirm: false
    });
  }

  returnConfirm() {
    this.popoverCtrl.dismiss({
      confirm: true
    });
  }

}
