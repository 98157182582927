import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss'],
})
export class MenuFooterComponent implements OnInit {
  vantagens: string;
  recarga: string;
  extrato: string;
  meusDados: string;
  mais: string;
  logged: boolean = false;
  showFooter: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeRef: ChangeDetectorRef,
    private utilService: UtilService
  ) {
    this.activatedRoute.params.subscribe(params => {
      let page: any = params;
      if (page.page) {
        this.changeClass(page.page);
      } else {
        this.vantagens = "selected";
      }
    });
  }

  ngOnInit() {
    window.addEventListener('keyboardWillHide', (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.showFooter = false;
      this.changeRef.detectChanges();
    });

    window.addEventListener('keyboardWillShow', (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.showFooter = true;
      document.activeElement.scrollIntoView(false);
      this.changeRef.detectChanges();
    });

    window.addEventListener('keyboardDidHide', (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.showFooter = false;
      this.changeRef.detectChanges();
    });

    window.addEventListener('keyboardDidShow', (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.showFooter = true;
      document.activeElement.scrollIntoView(false);
      this.changeRef.detectChanges();
    });
  }

  changeSelected(param) {
    this.utilService.analyticsClickEvent('menu', 'click', param);

    var user = localStorage.getItem("token");
    switch (param) {
      case "vantagens":
        this.router.navigate(['vantagens', { page: 'vantagens' }]);
        break;
      case "recarga":
        user ? this.router.navigate(['recarga', { page: 'recarga' }]) : this.router.navigate(['login', { page: 'recarga' }]);
        break;
      case "extrato":
        user ? this.router.navigate(['extrato', { page: 'extrato' }]) : this.router.navigate(['login', { page: 'extrato' }]);
        break;
      case "meus-dados":
        user ? this.router.navigate(['meus-dados', { page: 'meus-dados' }]) : this.router.navigate(['login', { page: 'meus-dados' }]);
        break;
      case "mais":
        this.router.navigate(['mais', { page: 'mais' }]);
        break;
    }
  }

  changeClass(param) {
    this.vantagens = "";
    this.recarga = "";
    this.extrato = "";
    this.meusDados = "";
    this.mais = "";
    switch (param) {
      case "vantagens":
        this.vantagens = "selected";
        break;
      case "recarga":
        this.recarga = "selected";
        break;
      case "extrato":
        this.extrato = "selected";
        break;
      case "meus-dados":
        this.meusDados = "selected";
        break;
      case "mais":
        this.mais = "selected";
        break;
    }
  }

}
