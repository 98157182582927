import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent implements OnInit {
  VALIDATE_MESSAGE = {
    'required': "Este campo é obrigatório",
    'invalidEmail': "E-mail inválido",
    'minlength': "Mínimo de 4 caracteres",
    'invalidCPF': "Este CPF é inválido",
    'invalidTel': "Telefone inválido",
    'requiredPhoto': "Foto necessária",
    'invalidMatchPassword': "O valor não confere com o campo senha",
    'invalidMatchEmail': "O valor não confere com o campo e-mail",
    'invalidMatchGiro': "O valor não confere com o campo número do Giro",
    'invalidKey': "A senha deve ter no mínimo 8 caracteres, números e uma letra maiúscula",
    'invalidBirthday': "Data inválida, a data deve ser digitada no formato: dd/mm/aaaa",
    'invalidSixteen': "Não é possível criar um cadastro com menos de 16 anos"
  };
  objkeys = Object.keys;
  @Input('formulario') formulario: any;
  @Input('campo') campo: string;
  constructor() { }

  ngOnInit() {}

}
