// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  MENSAGEM: {
    noConnection: "Recursos do aplicativo requerem internet.",
    errorService: "Erro no retorno de dados do servidor. Verifique a conexão com a internet.",
    errorLoginSBE: "Erro no login com MetrôRio",
    errorLogin: "Erro ao tentar fazer login.",
    errorLoginFacebook: "Erro no login com o facebook.",
    errorLoginGoogle: "Erro no login com o google.",
    errorLoginApple: "Erro no login com a apple.",
    internalError: "Erro interno.",
    errorRefreshToken: "Erro ao atualizar usuário.",
    errorRegister: "Erro no cadastro de usuário.",
    errorCodeRegister: "Por favor insira o token recebido por e-mail ou SMS para acessar sua conta Giro.",
    errorContact: "Erro ao enviar mensagem de contato.",
    mandatoryField: "Preencha os campos obrigatórios.",
    invalidCredentials: "E-mail ou senha inválidos.",
    userExists: "Usuário já cadastrado no Giro.",
    errorChangePass: "Erro na alteração de senha.",
    errorUpdateUser: "Erro ao editar usuário.",
    errorAddGiro: "Erro ao salvar cartão Giro.",
    errorBlockGiro: "Erro ao tentar bloquear cartão Giro.",
    giroExist: "Já existe um cartão Giro ativo para esse usuário.",
    errorCamera: "Erro ao capturar imagem.",
    errorFileCamera: "Erro ao converter imagem da câmera.",
    errorRecoverPassword: "Erro ao tentar recuperar senha.",
    alertCompraCartao: "Selecione o cartão de pagamento.",
    alertCompraValor: "Selecione a quantidade de passagens ou insira o valor.",
    alertNotPayCard: "Para efetuar recargas, você precisa cadastrar um cartão de pagamento. Vá até a tela de meus dados.",
    errorRecarga: "Revise os dados do seu cartão de crédito.",
    errorCardUser: "Número do cartão Giro não está vinculado a esse usuário.",
    errorDisablePayCard: "Erro ao remover cartão de pagamento.",
    errorAddPayCard: "Erro ao adicionar cartão de pagamento.",
    errorNotFoundGiro: "Para fazer uma recarga, por favor cadastre um cartão Giro. Vá até a tela de meus dados.",
    maxValue: "Valor máximo para recarga é R$ 500,00.",
    errorLogout: "Erro ao fazer logout na aplicação.",
    giroInvalid: "Cartão giro inválido ou existente para outro usuário.",
    errorPesquisa: "Erro ao enviar pesquisa.",
    usuarioBloqueado: "Não foi possível realizar o login. Por favor, entre em contato com o SAC."
  },
  TYPE: {
    info: "info",
    confirmPay: "confirmPay"
  },
  SYSTEM: {
     urlApi: "https://api-giro-dev.thera.com.br:4433/api/",
     path: "https://api-giro-dev.thera.com.br:4433/",
     SBE: "https://api-giro-dev.thera.com.br:4433/api/sbe/",
     tremAPI: { 
      url: 'https://api.ondeestameutrem.metrorio.app/v1',
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXByZXNhIjoibWFnYWx1IiwiYWRtaW5pc3RyYWRvciI6ZmFsc2UsImlkRW1wcmVzYSI6OCwiaWF0IjoxNjI3NDU2ODY3LCJleHAiOjI1NzQxODQ4Njd9.BHFDSau6nXos7U_SIa7zVWoOgl6DPIsmeu6v3YMdqss'
     },
    // urlApi: "https://localhost:44370/api/",
    // path: "https://localhost:44370/",
    // SBE: "https://localhost:44370/api/sbe/"
  },
  PUSHWOOSH: {
    projectid: "442242230577",
    appid: "1392C-7BBBF",
    serviceName: ""
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
