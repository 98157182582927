import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { SharedModule } from './modules/shared/shared.module';
import { EventService } from './services/event.service';
import { UtilService } from './services/util.service';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot({ animated: false, scrollAssist: true }), AppRoutingModule, HttpClientModule, SharedModule],
  providers: [
    StatusBar,
    SplashScreen,
    SpinnerDialog,
    AppVersion,
    FileTransfer,
    File,
    Keyboard,
    GooglePlus,
    Camera,
    EventService,
    UtilService,
    SignInWithApple,
    InAppBrowser,
    Facebook,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
