import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as Config } from 'src/environments/environment';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient, private utilService: UtilService) { }

  getMyUser(token: string) {
    return new Promise((res, rej) => {
      this.http.get(`${Config.SYSTEM.urlApi}users/myuser?complete=true`,
        { headers: this.utilService.headerPadrao() }).subscribe(d => {
          res(d);
        }, err => {
          rej(err);
        });
    });
  }

  registerUser(user: FormData) {
    return new Promise((res, rej) => {
      this.http.post(`${Config.SYSTEM.urlApi}Users`, user, {}).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

  addCardGiro(card: FormData, token: string) {
    return new Promise((res, rej) => {
      this.http.post(`${Config.SYSTEM.urlApi}GiroCard`, card, { headers: this.utilService.headerPadrao() }).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

  updatePassword(token: string, params: FormData) {
    return new Promise((res, rej) => {
      this.http.put(`${Config.SYSTEM.urlApi}users/UpdatePassword`, params,
        { headers: this.utilService.headerPadrao() }).subscribe((d: any) => {
          if (d.length > 0) {
            rej(d)
          } else {
            res(d);
          }
        }, err => {
          rej(err);
        });
    });
  }

  updateUser(token: string, user: FormData) {
    return new Promise((res, rej) => {
      this.http.put(`${Config.SYSTEM.urlApi}users/UpdateMyUser`, user,
        { headers: this.utilService.headerPadrao() }).subscribe(d => {
          res(d);
        }, err => {
          rej(err);
        });
    });
  }

  blockGiro(giroId: string, token: string) {
    return new Promise((res, rej) => {
      this.http.put(`${Config.SYSTEM.urlApi}GiroCard/BlockCard/${giroId}`, {}, { headers: this.utilService.headerPadrao() }).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

  blockGiroSBE(giro: string, token: string) {
    return new Promise((res, rej) => {
      this.http.put(`${Config.SYSTEM.urlApi}sbe/blockCard?cardId=${giro}`, {}, { headers: this.utilService.headerPadrao() }).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

  recoverPassword(email: FormData) {
    return new Promise((res, rej) => {
      this.http.put(`${Config.SYSTEM.urlApi}users/RecoverPassword`, email, {}).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

  getMyPaymentCard(token: string) {
    return new Promise((res, rej) => {
      this.http.get(`${Config.SYSTEM.urlApi}PaymentCard/mycards?active=true`, { headers: this.utilService.headerPadrao() }).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

  disablePayCard(token: string, cardId: string, objCard: any) {
    return new Promise((res, rej) => {
      this.http.put(`${Config.SYSTEM.urlApi}PaymentCard/${cardId}`, objCard, { headers: this.utilService.headerPadrao() }).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

  addPayCard(token: string) {
    return new Promise((res, rej) => {
      this.http.post(`${Config.SYSTEM.urlApi}PaymentCard`, {}, { headers: this.utilService.headerPadrao() }).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

  showTermPopup(token: string) {
    return new Promise((res, rej) => {
      this.http.get(`${Config.SYSTEM.urlApi}users/showTermPopup`,
        { headers: this.utilService.headerPadrao() }).subscribe(d => {
          res(d);
        }, err => {
          rej(err);
        });
    });
  }

  updateTermPopup(token: string, user: FormData) {
    return new Promise((res, rej) => {
      this.http.put(`${Config.SYSTEM.urlApi}users/UpdateCheckTerm`, user,
        { headers: this.utilService.headerPadrao() }).subscribe(d => {
          res(d);
        }, err => {
          rej(err);
        });
    });
  }

  deleteMyUser(token: string, user: FormData) {
    return new Promise((res, rej) => {
      this.http.put(`${Config.SYSTEM.urlApi}users/DeleteMyUser`, user,
        { headers: this.utilService.headerPadrao() }).subscribe(d => {
          res(d);
        }, err => {
          rej(err);
        });
    });
  }

}
