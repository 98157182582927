import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonContent, ModalController, PopoverController } from '@ionic/angular';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';
import { UtilService } from '../../services/util.service';
import { environment as Config } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ConfirmComponent } from '../../components/confirm/confirm.component';

@Component({
  selector: 'app-modal-deletar-conta',
  templateUrl: './modal-deletar-conta.component.html',
  styleUrls: ['./modal-deletar-conta.component.scss'],
})
export class ModalDeletarContaComponent implements OnInit {
  @ViewChild(IonContent, { read: IonContent, static: false }) content: IonContent;

  visivel: string = "invisivel";
  formDeletar: FormGroup;
  formulario: any = {
    categoria: 'outros',
    conteudo: ''
  };
  etapaUm: boolean = true;

  constructor(
    public formBuilder: FormBuilder,
    private utilService: UtilService,
    private userService: UserService,
    private loginService: LoginService,
    private router: Router,
    public popoverCtrl: PopoverController,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.formDeletar = this.formBuilder.group({
      categoria: new FormControl(null, [Validators.required]),
      conteudo: new FormControl(null, [])
    });
  }

  logScrolling(ev) {
    if (ev.detail.scrollTop > 0) {
      this.visivel = "";
    } else {
      this.visivel = "invisivel";
    }
  }

  ionViewWillEnter() {
    this.utilService.analyticsViewPage('deletar-conta', 'deletar conta');
  }

  logChangeRadio(tipo) {
    this.utilService.analyticsClickEvent('deletar-conta', 'click', tipo);
  }

  enviar() {
    if (this.formDeletar.valid) {
      this.etapaUm = false;
    }
  }

  deleteMyUser() {
    let token = localStorage.getItem("token");
    let user = new FormData();

    this.userService.getMyUser(token).then((d: any) => {
      let usuario = d;
      let reason = "";
      if (this.formulario.categoria == "naoAndo") {
        reason = "não ando mais de metrô"
      } else if (this.formulario.categoria == "problema") {
        reason = "tive um problema com o serviço";
      }
      user.append("id", usuario.id);
      user.append("name", usuario.name);
      user.append("lastName", usuario.lastName);
      user.append("phone", usuario.phone);
      user.append("reasondelete", reason);
      user.append("reasonextendeddelete", this.formulario.conteudo);

      this.userService.deleteMyUser(token, user).then(d => {
        var usuario: any = d;
        console.log(usuario);
        this.logout(false);

        var modalInfoAtualizada: any = {
          title: "Sua conta foi excluída.",
          text: "Você poderá continuar recarregando e consultando o saldo do seu cartão apenas nas estações. Esta exclusão não impede a criação de uma nova conta futuramente.",
          textButton: "",
          buttonShow: false,
          type: Config.TYPE.info
        }

        let modal = this.popoverCtrl.create({
          component: ConfirmComponent,
          translucent: true,
          componentProps: {
            temp: Object.assign({}, modalInfoAtualizada)
          },
          cssClass: 'filtro-modal'
        });

        modal.then((modal) => modal.present());
      }).catch(e => {
        if (e.status == 401) {
          this.refreshToken("deleteMyUser");
        } else {
          this.utilService.presentToast({ msg: Config.MENSAGEM.errorService });
        }
      });
    }).catch(e => {
      if (e.status == 401) {
        this.refreshToken("deleteMyUser");
      } else {
        this.utilService.presentToast({ msg: Config.MENSAGEM.errorService });
      }
    });
  }

  refreshToken(method: string) {
    this.loginService.refreshToken().then(d => {
      var objUser: any = d;
      localStorage.setItem("token", objUser.accessToken);
      localStorage.setItem("refreshToken", objUser.refreshToken);
      if (method == "deleteMyUser") {
        this.deleteMyUser();
      }
    }).catch(err => {
      this.logout();
    });
  }

  logout(route: boolean = true) {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("email");
    route ? this.router.navigate(["login", { page: "meus-dados" }]) : this.router.navigate(["home", {}]);
    this.close(true);
  }

  close(deletado: boolean) {
    this.modalCtrl.dismiss({ deletado: deletado });
  }
}
