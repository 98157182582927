import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }
  public canGoHome = false;
  ngOnInit() {
    setInterval(() => {
      let show = ['/recarga','/extrato','reveillon','/meus-dados','/onde-esta-trem'].some(x => window.location.href.toLocaleLowerCase().indexOf(x) !== -1);
      if(this.canGoHome != show) this.canGoHome = show;
    }, 100);
  }
  openMenu() {
    this.router.navigate(['mais', { page: 'mais' }]);
  }
  goToHome() {
    this.router.navigate(['home', {page: "home"}]);
  }

}
