import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import { BrMaskerModule } from 'br-mask';
import { ModalAtualizacaoPrivacidadeComponent } from '../../components/modal-atualizacao-privacidade/modal-atualizacao-privacidade.component';
import { ConfirmComponent } from '../../components/confirm/confirm.component';
import { HeaderComponent } from '../../components/header/header.component';
import { LoadingComponent } from '../../components/loading/loading.component';
import { MenuFooterComponent } from '../../components/menu-footer/menu-footer.component';
import { ModalExtratoFiltroComponent } from '../../components/modal-extrato-filtro/modal-extrato-filtro.component';
import { PopupBannerComponent } from '../../components/popup-banner/popup-banner.component';
import { ValidationMessageComponent } from '../../components/validation-message/validation-message.component';
import { ModalDeletarContaComponent } from '../../components/modal-deletar-conta/modal-deletar-conta.component'
import { SituacaoLinhasComponent } from 'src/app/components/situacao-linhas/situacao-linhas.component';
import { FormLoginComponent } from 'src/app/components/form-login/form-login.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuFooterComponent,
    FormLoginComponent,
    SituacaoLinhasComponent,
    ValidationMessageComponent,
    ModalExtratoFiltroComponent,
    PopupBannerComponent,
    ConfirmComponent,
    LoadingComponent,
    ModalAtualizacaoPrivacidadeComponent,
    ModalDeletarContaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule
  ],
  exports: [
    HeaderComponent,
    MenuFooterComponent,
    SituacaoLinhasComponent,
    ValidationMessageComponent,
    ModalExtratoFiltroComponent,
    PopupBannerComponent,
    ConfirmComponent,
    LoadingComponent,
    BrMaskerModule,
    ModalAtualizacaoPrivacidadeComponent,
    ModalDeletarContaComponent,
    ReactiveFormsModule,
    FormLoginComponent
  ],
  providers: [
    AppVersion,
    FileTransfer,
    File,
    LaunchReview,
    AppRate
  ],
  entryComponents: [
    ModalExtratoFiltroComponent,
    PopupBannerComponent,
    ConfirmComponent,
    LoadingComponent,
    ModalAtualizacaoPrivacidadeComponent,
    ModalDeletarContaComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
