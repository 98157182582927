import { Component, OnInit, Renderer2 } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {

  constructor(
    public renderer : Renderer2,
    public modalCtrl : ModalController
  ) { }

  ngOnInit() {
    this.renderer.addClass((this as any).modal, 'popup-banner');
  }

}
