import { Component } from '@angular/core';

import { Platform, ToastController, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { environment as Config } from 'src/environments/environment';
import { ConfirmComponent } from '../app/components/confirm/confirm.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private appVersion: AppVersion,
    private toastController: ToastController,
    private keyboard: Keyboard,
    private popoverCtrl: PopoverController
  ) {
    this.platform.ready().then(() => {
      this.keyboard.hideFormAccessoryBar(false);
      this.keyboard.disableScroll(true);
      this.appVersion.getVersionNumber().then(d => {
        let version: any = d;
        this.initializeApp(version);
      }).catch(err => {
        this.initializeApp(null);
      });
    });
  }

  async initializeApp(version) {
    this.platform.ready().then(() => {
      /*const versionOld: any = localStorage.getItem("v");
      if (!versionOld) {
        localStorage.setItem('v', version);
        this.router.navigate(['splashscreen']);
      } else if (versionOld !== version) {
        localStorage.setItem('v', version);
        this.router.navigate(['splashscreen']);
      }*/
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString('#005DA4');
      this.splashScreen.hide();
      this.initPushwoosh();
    });
  }

  ngOnInit() { }

  initPushwoosh() {
    var pushNotification = (<any>window).plugins.pushNotification;

    document.addEventListener('push-notification',
      function (event) {
        var userData = (<any>event).notification.userdata;
        if (typeof (userData) != "undefined") {
          console.warn('user data: ' + JSON.stringify(userData));
        }
      }
    );

    var platformAux: any = this.platform;
    var popoverAux: any = this.popoverCtrl;
    document.addEventListener('push-receive',
      function (event) {
        var message = (<any>event).notification.message;
        var userData = (<any>event).notification.userdata;

        var title = "";
        if (platformAux.is("android")) {
          title = (<any>event).notification.android.header;
        } else {
          title = (<any>event).notification.ios.aps.alert.title;
        }

        var modalPush: any = {
          title: title,
          text: message,
          textButton: "",
          buttonShow: false,
          type: Config.TYPE.info
        }

        let modal = popoverAux.create({
          component: ConfirmComponent,
          translucent: true,
          componentProps: {
            temp: Object.assign({}, modalPush)
          },
          cssClass: 'filtro-modal'
        });
        modal.then((modal) => modal.present());

        if (typeof (userData) != "undefined") {
          console.warn('user data: ' + JSON.stringify(userData));
        }
      }
    );

    pushNotification.onDeviceReady(Config.PUSHWOOSH);

    pushNotification.registerDevice(
      function (status) {
        console.warn(status.pushToken);
        console.log(status.pushToken);
      },
      function (status) {
        console.warn(JSON.stringify(['failed to register ', status]));
      }
    );
  }
}
