import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as Config } from 'src/environments/environment';
import { IBusca, ICreateLocal, IUpdateLocal } from '../onde-esta-trem/interfaces';

@Injectable({
  providedIn: 'root'
})
export class OndeEstaTremService {
  baseHeaders: HttpHeaders = null;

  constructor(private http: HttpClient) {
    this.baseHeaders = new HttpHeaders({
      'Authorization': `Bearer ${Config.SYSTEM.tremAPI.token}`
    });
  }

  getStatusLinhas() {
    return this.http.get(`${Config.SYSTEM.tremAPI.url}/StatusLinha`, { headers: this.baseHeaders }).toPromise();
  };

  getEstacoes() {
    return this.http.get(`${Config.SYSTEM.tremAPI.url}/estacao`, { headers: this.baseHeaders }).toPromise();
  }

  getRota(origem: string, destino: string) {
    return this.http.get(`${Config.SYSTEM.tremAPI.url}/OndeEstaMeuTrem/${origem}/${destino}`, { headers: this.baseHeaders }).toPromise();
  }

  getMatrizOD() {
    return this.http.get(`${Config.SYSTEM.tremAPI.url}/matrizOD`, { headers: this.baseHeaders }).toPromise();
  }

  updateLocal(data: IUpdateLocal) {

  }
  createLocal(data: ICreateLocal) {
    
  }
}
