import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as Config } from 'src/environments/environment';
import { EventService } from './event.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(public http: HttpClient, private eventService: EventService, private utilService: UtilService) {

  }

  login(login: string, senha: string, gType: string, code: string) {
    return new Promise((resolve, reject) => {
      let params = { grantType: gType, userID: login, accessKey: senha, activationCode: code };
      this.http.post(`${Config.SYSTEM.urlApi}Login`, params, {}).subscribe(d => {

        resolve(d);
      }, err => {
        reject(err);
      });
    });
  }

  refreshToken() {
    return new Promise((res, rej) => {
      let key = localStorage.getItem("refreshToken");
      let gType = "refresh_token";
      let params = { grantType: gType, userID: "", accessKey: key };
      this.http.post(`${Config.SYSTEM.urlApi}Login`, params, {}).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

  logout(token) {
    return new Promise((res, rej) => {
      this.http.post(`${Config.SYSTEM.urlApi}Logout`, {}, { headers: this.utilService.headerPadrao() }).subscribe(d => {
        res(d);
      }, err => {
        rej(err);
      });
    });
  }

}
