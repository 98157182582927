import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment as Config } from 'src/environments/environment';
import { Platform, PopoverController, ToastController } from '@ionic/angular';
import { ConfirmComponent } from '../components/confirm/confirm.component';
declare var localStorage: Storage;
declare var dataLayer: Array<any>;

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private _eventList = [];

  constructor(
    private toast: ToastController,
    public popoverCtrl: PopoverController,
    private platform: Platform
  ) { }

  headerPadrao() {
    return new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    });
  }

  arrayToHtml(lista: Array<any>) {
    var retorno = "<b>Erros de validação da senha </b></br><ol>"
    for (let index = 0; index < lista.length; index++) {
      const element = lista[index].description;
      retorno += '<li>' + element.toString() + '</li>';
    }
    return retorno + '</ol>';
  }

  async presentToast({ msg, alertMode, delay, color, buttonText }: { msg: string, alertMode?: boolean, delay?: number, color?: string, buttonText?: string } = { msg: "", alertMode: false, delay: 3000, color: "danger", buttonText: "Fechar" }) {
    if (color == undefined) { color = "danger" }
    if (delay == undefined) { delay = 3000 }
    if (buttonText == undefined) { buttonText = "fechar" }
    if (alertMode == undefined) { alertMode = false }
    if (!alertMode) {
      const toast = await this.toast.create({
        message: msg,
        duration: delay,
        color: color,

      });
      toast.present();
    } else {
      const toast = await this.toast.create({
        message: msg,
        color: color,
        buttons: [
          {
            text: buttonText,
            role: 'cancel',
          },
        ]
      });
      toast.present();
    }
  }

  showModalBlackList() {
    var modalRemover: any = {
      title: "Encontramos um problema.",
      text: "Ops, identificamos um problema no seu cartão. Dirija-se a uma estação e realize a troca gratuitamente. Em caso de dúvida, acesse o chat no site MetrôRio.",
      textButton: "",
      buttonShow: false,
      type: Config.TYPE.info
    }
    let modal = this.popoverCtrl.create({
      component: ConfirmComponent,
      translucent: true,
      componentProps: {
        temp: Object.assign({}, modalRemover)
      }
    });

    modal.then((modal) => modal.present());

    modal.then((modal) => {
      modal.onDidDismiss().then((data) => { });
    });
  }

  analyticsViewPage(pagina, nomePagina) {
    dataLayer.push({
      'screenPath': pagina,
      'screenName': nomePagina,
      'sessionID': localStorage.getItem("email") == null ? "" : localStorage.getItem("email")
    });
    dataLayer.push({ 'event': 'appScreenView' });
  }

  analyticsClickEvent(categoria, acao, labelEvento) {
    if (this.platform.is("android")) {
      dataLayer.push({
        'appEventCategory': categoria,
        'appEventAction': acao,
        'appEventLabel': labelEvento,
        'sessionID': localStorage.getItem("email") == null ? "" : localStorage.getItem("email"),
        'clientEmail': localStorage.getItem("email") == null ? "" : localStorage.getItem("email")
      });
      dataLayer.push({ 'event': 'appEventCustom' });
    } else {
      dataLayer.push({
        'appEventCategory': categoria,
        'appEventAction': acao,
        'appEventLabel': labelEvento,
        'sessionID': localStorage.getItem("email") == null ? "" : localStorage.getItem("email"),
      });
      dataLayer.push({ 'event': 'appEvent' });
    }
  }

}
