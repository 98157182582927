import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { UtilsValidator } from '../../validators/utils';
import { Router } from '@angular/router';
import { environment as Config } from 'src/environments/environment';
import { LoadingController, PopoverController } from '@ionic/angular';
import { UserService } from '../../services/user.service';
import { ConfirmComponent } from '../../components/confirm/confirm.component';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss'],
})
export class FormLoginComponent implements OnInit {
  public formLogin: FormGroup = new FormGroup({
    login: new FormControl(null, [Validators.required, UtilsValidator.email]),
    senha: new FormControl(null, [Validators.required]),
    codigoAcesso: new FormControl(null, [])
  });
  user: any = {
    login: "",
    senha: "",
    codigoAcesso: ""
  }
  typeSenha: string = "password";
  routePage: string = "vantagens";
  showCode: boolean = false;
  recoverErr: boolean = false;
  classOlho: string = "no-active";

  constructor(
    public formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private userService: UserService,
    private popoverCtrl: PopoverController,
    private utilService: UtilService,
    private loadingCtrl: LoadingController
  ) {
  }

  forceLower() {
    this.user.login = this.user.login.toLowerCase();
    this.user.login = this.user.login.replace(/\s/g, "");
  }

  ionViewWillEnter() {
    this.utilService.analyticsViewPage('login-email', 'login por email');
  }

  ngOnInit() {
    this.formLogin = this.formBuilder.group({
      login: new FormControl(null, [Validators.required, UtilsValidator.email]),
      senha: new FormControl(null, [Validators.required]),
      codigoAcesso: new FormControl(null, [])
    });
  }

  entrar() {
    this.utilService.analyticsClickEvent('login-email', 'click', 'entrar');
    if (this.formLogin.valid) {
      this.loadingCtrl.create().then((loading) => {
        loading.present();
        this.efetuaLogin(loading);
      });
    } else {
      this.formLogin.markAllAsTouched();
    }
  }

  private efetuaLogin(loading: HTMLIonLoadingElement): void {
    debugger;
    this.loginService.login(this.user.login, this.user.senha, "password", this.user.codigoAcesso).then(d => {
      let obj: any = d;
      localStorage.setItem("token", obj.accessToken);
      localStorage.setItem("refreshToken", obj.refreshToken);
      localStorage.setItem("email", this.user.login);

      if (obj.msg !== "") {
        this.utilService.presentToast({ msg: obj.msg, delay: 5000 })
      }
      this.router.navigate(["recarga", { page: "recarga" }]);
    }).catch(err => {
      if (this.isError403(err.error)) {
        this.utilService.presentToast({ msg: Config.MENSAGEM.usuarioBloqueado });
      } else {
        if (err.error.cod == "401#0003") {
          this.utilService.presentToast({ msg: Config.MENSAGEM.invalidCredentials });
        } else if (err.error.cod == "401#0002") {
          this.showCode = true;
          this.utilService.presentToast({ msg: Config.MENSAGEM.errorCodeRegister });
        } else {
          this.utilService.presentToast({ msg: Config.MENSAGEM.errorLogin });
        }
      }
    }).finally(() => loading.dismiss());
  }

  isError403(err): boolean {
    return ((typeof err.error === "number") && (err.error == 403)) || ((typeof err.errorNumber === "number") && (err.errorNumber == 403));
  }

  recuperarSenha() {
    this.utilService.analyticsClickEvent('login-email', 'click', 'esqueci minha senha');

    if (this.user.login) {
      this.recoverErr = false;
      var email = new FormData();
      email.append("email", this.user.login);
      this.userService.recoverPassword(email).then(d => {
        this.showMsgRecoverPassword();
      }).catch(err => {
        this.utilService.presentToast({ msg: Config.MENSAGEM.errorRecoverPassword });
      });
    } else {
      this.recoverErr = true;
    }
  }

  criarConta() {
    this.router.navigate(['registro']);
  }

  clickOlho() {
    if (this.typeSenha == "password") {
      this.typeSenha = "text";
      this.classOlho = "active";
    } else {
      this.typeSenha = "password";
      this.classOlho = "no-active";
    }
  }

  showMsgRecoverPassword() {
    var modalInfoAtualizada: any = {
      title: "Senha recuperada",
      text: "Você receberá no seu e-mail uma nova senha provisória, lembre de altera-la quando acessar novamente o aplicativo.",
      textButton: "",
      buttonShow: false,
      type: Config.TYPE.info
    }

    let modal = this.popoverCtrl.create({
      component: ConfirmComponent,
      translucent: true,
      componentProps: {
        temp: Object.assign({}, modalInfoAtualizada)
      },
      cssClass: 'filtro-modal'
    });

    modal.then((modal) => modal.present());
  }

}
